<template>
  <v-container fluid class="mt-5">
    <v-row class="mt-5 mb-5" justify="space-between">
      <v-col cols="12" sm="4" md="3">
        <v-text-field
          @input="updateData"
          @keyup.enter="HandlerGetCuopon"
          v-model.trim="meli_id"
          filled
          rounded
          dense
          class="rounded-small"
          name="ciudad"
          label="ID Mercadolibre"
          single-line
          hide-details
        ></v-text-field>
      </v-col>
      <v-col class="d-flex justify-end" cols="12" md="2">
        <v-spacer></v-spacer>
        <v-btn
          fab
          small
          color="primary"
          class="mr-3"
          @click="HandlerGetCuopon()"
        >
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
        <v-btn fab small @click="HandlerReset()">
          <v-icon color="primary">mdi-backup-restore</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12">
        <v-card class="shadow-1 mt-5">
          <v-data-table
            :loading="loading"
            :headers="headers"
            :items="dataCoupon"
            disable-pagination
            hide-default-footer
            class="elevation-0"
            :page.sync="page"
            @page-count="pageCount = $event"
          >
            <template v-slot:[`item.full_unit_price`]="{ item }">
              <div>
                {{ item.full_unit_price | currency }}
              </div>
            </template>
            <template v-slot:[`item.amount_coupons`]="{ item }">
              <div>
                {{ item.amount_coupons | currency }}
              </div>
            </template>
            <template v-slot:[`item.percentage`]="{ item }">
              <div>{{ percentage(item) }}%</div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="end">
      <v-col cols="6" md="4">
        <div>
          <v-pagination
            v-model="page"
            :length="pagination.lastPage"
            circle
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      meli_id: "",
      headers: [
        { text: "ID orden", sortable: false, value: "order_id" },
        { text: "ID ML", sortable: false, value: "meli_id" },
        { text: "Producto", sortable: false, value: "product.name" },
        { text: "SKU", sortable: false, value: "product.sku" },
        { text: "Precio", sortable: false, value: "full_unit_price" },
        { text: "Precio Cupon", sortable: false, value: "amount_coupons" },
        { text: "Porcentaje", sortable: false, value: "percentage" },
      ],
      dataCoupon: [],
      page: 1,
      pagination: {},
    };
  },

  created() {
    this.HandlerGetCuopon();
  },

  watch: {
    page(val) {
      this.HandlerGetCuopon(val);
    },
  },

  computed: {
    isDark() {
      return this.$vuetify.theme.dark;
    },
  },

  methods: {
    updateData(input) {
      this.meli_id = input.replace(" ", "");
    },

    async HandlerGetCuopon(page = 1) {
      try {
        this.loading = true;
        const request = {
          meli_id: this.meli_id,
          page: page,
          per_page: 20,
          paginate: true,
        };

        const response = await this.$store.dispatch(
          "publications/GET_COUPON",
          request
        );

        this.dataCoupon = response.data.data.data;
        this.pagination = {
          lastPage: response.data.data.lastPage,
          page: response.data.data.page,
          perPage: response.data.data.perPage,
          total: response.data.data.total,
        };
        this.meli_id = "";
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    HandlerReset() {
      this.meli_id = "";
      this.HandlerGetCuopon();
    },

    percentage(item) {
      const percentage =
        100 -
        ((item.unit_price - item.amount_coupons) * 100) / item.full_unit_price;
      return percentage.toFixed(2);
    },
  },
};
</script>

<style>
</style>